<template>
  <div
    class="archive content-padding"
    v-if="dropdownList.length"
    :key="Object.keys(labels).length"
  >
    <div class="archive__dropdown">
      <Dropdown :list="dropdownList" @changed="selectArchive" :value="value" />
    </div>
    <div class="archive__sub-header">
      <div>{{ labels.you_can_select_archive_type }}</div>
    </div>
    <div class="archive__table">
      <TableCard :settings="settings" :data-array="kindData" />
    </div>
    <SvgLoader :loading="!kindData.length && !dataReceived ? true : false" />
    <h5 v-if="!kindData.length && dataReceived" class="text-center w-100">
      {{ labels.no_archive_data }}
    </h5>
    <nav
      aria-label="Page navigation example"
      class="navigation archive__navigation"
      v-if="checkPagination"
    >
      <ul class="pagination">
        <li
          :class="['page-item', { active: page === pagination.current }]"
          v-for="page in pagination.total"
          :key="page"
          @click="pagination.current = page"
        >
          <span class="page-link">{{ page }}</span>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import TableCard from "@/components/Table/TableCard";
import Dropdown from "@/components/Dropdowns/Dropdown.vue";
import SvgLoader from "@/components/Preloaders/SvgLoader";

import { mapActions, mapGetters } from "vuex";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faTrashRestore } from "@fortawesome/free-solid-svg-icons";
const colors = require("/colors.config.json");
library.add(faTrash, faTrashRestore);

export default {
  name: "Archive",
  components: {
    TableCard,
    Dropdown,
    SvgLoader,
  },
  data() {
    return {
      pagination: {},
      items: {},
      permissions: {},
      value: this.$route.query.kind ? this.$route.query.kind : "",
      kindData: [],
      dataReceived: false,
    };
  },
  mounted() {
    // this.loadLabels()
    //   .then(() => {
    //     this.getUserReadArchivePermissions(() => {
    //       this.getArchiveList();
    //       this.setQueryPage();
    //     });
    //   })
    //   .catch((err) => console.log(err));

    if (this.$route.query.kind) this.selectArchive(this.$route.query.kind);
    else {
      this.setQueryKind("Module");
      if (this.$route.query.kind) this.selectArchive(this.$route.query.kind);
    }
  },
  watch: {
    currentPage(newPage, oldPage) {
      let queryPage = "1";
      if (this.$route.query.page) queryPage = this.$route.query.page;

      if (Number(newPage) !== Number(oldPage) && oldPage == queryPage) {
        this.getArchiveList();
        this.setQueryPage();
      }
    },
    $route(to, from) {
      let fromPage = "1";
      if (from.query.page) fromPage = from.query.page;

      if (
        to.query.page !== from.query.page &&
        to.name === from.name &&
        this.currentPage == fromPage &&
        !(!from.query.page && to.query.page == "1")
      ) {
        let page = to.query.page ? to.query.page : "1";
        this.getArchiveList(page);
      }
    },
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    currentPage() {
      let page = 1;

      if (this.pagination && this.pagination.current) {
        page = this.pagination.current;
      } else if (this.$route.query.page) {
        page = this.$route.query.page;
      }

      return page;
    },
    checkPagination() {
      if (!this.pagination || !this.items) return false;

      return (
        Object.entries(this.pagination).length &&
        this.items.total / (this.items.limit || 20) > 1
      );
    },
    dropdownList() {
      return [
        {
          label: this.labels.module,
          value: "Module",
        },
        {
          label: this.labels.monetization_bundle,
          value: "MonetizationBundle",
        },
        {
          label: this.labels.affiliate_link,
          value: "AffiliateLink",
        },
        {
          label: this.labels.card,
          value: "Card",
        },
        {
          label: this.labels.contract,
          value: "Contract",
        },
        {
          label: this.labels.menu,
          value: "Menu",
        },
        {
          label: this.labels.message,
          value: "Message",
        },
        {
          label: this.labels.page,
          value: "Page",
        },
      ];
    },
    settings() {
      return {
        header: [
          {
            headerLabel: this.labels.name,
            headerColor: `${colors.gray_text}`,
            key: "title",
            computeFn: (input) => input,
            bodyColor: "#2C3E50",
            columnWidth: "0 0 30%",
            whiteSpace: "nowrap",
          },
          {
            headerLabel: "ID",
            headerColor: `${colors.gray_text}`,
            key: "_id",
            computeFn: (input) => input,
            bodyColor: "#7F8C8D",
            columnWidth: "0 0 28%",
            whiteSpace: "nowrap",
          },
          {
            headerLabel: this.labels.last_action,
            headerColor: `${colors.gray_text}`,
            key: "lastAction",
            computeFn: (input) => {
              return input && input.date
                ? `${input.action} on ${input.date}`
                : "no data";
            },
            bodyColor: "#7F8C8D",
            columnWidth: "1 1 auto",
            whiteSpace: "nowrap",
          },
        ],
        gap: "20px",
        minTableWidth: "300px",
        actionsWidth: "0 0 130px",
        actionsHeaderLabel: "", //this.labels.actions,
        actions: [
          {
            label: this.labels.restore,
            variant: "btn-green",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (data) => this.restoreAction(data._id),
          },
          {
            label: this.labels.delete,
            variant: "btn-danger",
            styles: { padding: "8px 15px", fontWeight: "700" },
            action: (data) => this.confirmDelete(data._id),
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("user", {
      getUserSelectedPermissions: "getUserSelectedPermissions",
    }),
    ...mapActions("archive", {
      getList: "getList",
      restore: "restore",
      _delete: "_delete",
    }),
    async getUserReadArchivePermissions(callback) {
      let collections = [];
      for (let i = 0; i < this.dropdownList.length; i++) {
        collections.push(this.dropdownList[i].value);
      }

      let readArchivePermissions = await this.getUserSelectedPermissions({
        collection: collections,
        user: "",
      });

      if (readArchivePermissions.data) {
        let collectionsFromDB = Object.keys(readArchivePermissions.data);

        for (let i = 0; i < collectionsFromDB.length; i++) {
          if (
            readArchivePermissions.data[collectionsFromDB[i]] &&
            ((typeof readArchivePermissions.data[collectionsFromDB[i]][
              "read_archive"
            ] !== "undefined" &&
              readArchivePermissions.data[collectionsFromDB[i]][
                "read_archive"
              ] !== 1) ||
              typeof readArchivePermissions.data[collectionsFromDB[i]][
                "read_archive"
              ] === "undefined")
          ) {
            let index = this.dropdownList
              .map(function (e) {
                return e.value;
              })
              .indexOf(collectionsFromDB[i]);
            if (index > -1) {
              this.dropdownList.splice(index, 1);
            }
          }
        }

        if (this.dropdownList.length) {
          let kind = this.$route.query.kind || "Module";
          this.setQueryKind(kind);
          this.value = kind;
        }
      }

      if (typeof callback === "function") return callback();
    },
    selectArchive(key) {
      this.value = key;
      this.setQueryKind(key);

      if (this.pagination.current != 1) {
        this.pagination.current = 1;
        this.getArchiveList();
      } else this.getArchiveList();
    },
    currentUserCan(action, _id) {
      if (
        _id &&
        this.permissions &&
        this.permissions[_id] &&
        this.permissions[_id][action] &&
        this.permissions[_id][action] == 1
      ) {
        return true;
      }
      if (
        this.permissions &&
        this.permissions[action] &&
        this.permissions[action] == 1
      ) {
        return true;
      }
      return false;
    },
    setQueryPage(page) {
      if (
        !this.$route.query.page ||
        this.$route.query.page != this.currentPage
      ) {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: page ? page : this.currentPage,
          },
        });
      }
    },
    setQueryKind(kind) {
      if (kind && kind !== this.$route.query.kind) {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            kind: kind,
          },
        });
      }
    },
    getArchiveList(page) {
      let data = { page: this.currentPage, kind: this.value };
      if (page) data.page = page;
      this.dataReceived = false;

      this.getList(data)
        .then((res) => {
          if (res.success) {
            this.items = res.items;
            this.pagination = res.pages;
            if (res.permissions) {
              this.permissions = res.permissions;
            }

            this.kindData = res.data;
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.errors,
              labels: this.labels,
            });
          }

          this.dataReceived = true;
        })
        .catch((err) => console.log(err));
    },
    confirmDelete(id) {
      let notificationObj = {
        variant: "danger",
        msg: this.labels.are_you_sure_you_want_delete,
        buttons: [
          {
            title: this.labels.delete,
            variant: "danger",
            fn: () => this.deleteAction(id),
          },
        ],
      };

      this.addNotification(notificationObj);
    },
    deleteAction(id) {
      let kind = this.value
        .split(/(?=[A-Z])/)
        .join("-")
        .toLowerCase();

      let data = { id: id, kind: kind };

      let labelKey = this.value
        .split(/(?=[A-Z])/)
        .join("_")
        .toLowerCase();

      this._delete(data)
        .then((res) => {
          if (res.success) {
            if (this.kindData.length < 1) this.pagination.current = 1;
            else this.getArchiveList();

            this.addNotification({
              variant: "success",
              msg: `${this.labels[labelKey]}: ${this.labels.deleted}`,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    restoreAction(id) {
      let kind = this.value
        .split(/(?=[A-Z])/)
        .join("-")
        .toLowerCase();
      let data = { id: id, kind: kind };
      let labelKey = this.value
        .split(/(?=[A-Z])/)
        .join("_")
        .toLowerCase();

      this.restore(data)
        .then((res) => {
          if (res.success) {
            if (this.kindData.length < 1) this.pagination.current = 1;
            else this.getArchiveList();
            this.addNotification({
              variant: "success",
              msg: `${this.labels[labelKey]}: ${this.labels.restored}`,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
.archive {
  &__dropdown {
    width: 300px;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 20px;

    @media (min-width: 992px) {
      margin-bottom: 30px;
    }
  }

  &__dropdown,
  &__sub-header,
  &__table,
  &__navigation {
    margin-bottom: 20px;

    @media (min-width: 992px) {
      margin-bottom: 30px;
    }
  }

  &__navigation {
    display: flex;
    justify-content: center;

    .pagination {
      margin-bottom: 0;

      li {
        cursor: pointer;
      }
    }
  }
}
</style>
